@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:
    Lexend,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Lexend, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --Primary: #407ec4;
  --toastify-text-color-light: #333333 !important;
  --toastify-color-success: #26731f !important;
  --toastify-color-warning: #f0a741 !important;
  --toastify-color-error: #c41a1a !important;
  --toastify-color-info: #407ec4 !important;
}
html {
  font-size: 13px;
}

@media (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}
