.signUp-background-wrapper {
    background: url("../../assets/signUpPageBackgroundImage.png");
    background-position: center;
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
    height: 100vh !important;
    width: 100vw !important;
  }
  .upper-ring{
    position: absolute !important;
    top:0 !important;
    right:0 !important;
    width:6rem !important;
  }
  
  .bottom-ring{
    position: absolute !important;
    bottom:0 !important;
    right:0 !important;
    width:10rem !important;
  }
  