.number-card-wrapper {
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 2px 24px 0px #e7f2fa;
  padding: 1.25rem;
}
.customHr {
  background: #407ec4;
}
.number-card-description {
  color: #5b5b5b;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
}

@media (min-width: 1024px) {
  .number-card-wrapper {
    background: none;
    box-shadow: none;
    padding: 1.25rem;
    transition: all 0.3s ease-out;
  }
  .customHr {
    border: 1px solid #d1dae7;
    transition: all 0.3s ease-out;
  }
  .number-card-wrapper:hover {
    background: #fff;
    box-shadow: 0px 2px 24px 0px #e7f2fa;
  }
  .number-card-wrapper:hover .customHr {
    border: 1px solid #407ec4;
  }
}
