.gem-description-mobile {
  color: var(--Text-Secondary, #5b5b5b);
  text-align: center;

  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
}

.our-gems-wrapper {
  height: 30rem;
  position: relative;
}
@keyframes eclipse {
  0% {
    border-radius: 63% 42% 60% 51%/45% 37% 66% 68%;
  }
  50% {
    border-radius: 82% 35% 58% 60%/53% 34% 82% 69%;
  }
  75% {
    border-radius: 42% 66% 61% 51%/57% 51% 53% 57%;
  }
  100% {
    border-radius: 63% 42% 60% 51%/45% 37% 66% 68%;
  }
}

.our-gems-eclipse {
  height: 30rem;
  width: 90%;
  opacity: 20%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  border: 0.1rem solid #979797;
  border-radius: 50% 50% 50% 50%/50% 50% 50% 50%;
  animation: eclipse 10s infinite;
}

@keyframes blob-bg {
  0% {
    border-radius: 63% 42% 60% 51%/45% 37% 66% 68%;
  }
  50% {
    border-radius: 82% 35% 58% 60%/53% 34% 82% 69%;
  }
  75% {
    border-radius: 42% 66% 61% 51%/57% 51% 53% 57%;
  }
}
.gemEcosystem-logo-wrapper {
  height: 20rem;
  width: 20rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    47deg,
    #407ec4 -12.64%,
    rgba(64, 126, 196, 0.6) 28.46%,
    rgba(64, 126, 196, 0.8) 66.63%,
    #407ec4 85.22%
  );
  border-radius: 63% 42% 60% 51%/45% 37% 66% 68%;
  animation: blob-bg 10s infinite;
}
.logo-wrapper {
  height: 15rem;
  width: 15rem;
  border-radius: 12.5rem;
  background: linear-gradient(
    218deg,
    #f2fff3 12.51%,
    rgba(234, 250, 235, 0.8) 97.79%
  );
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
}

.gem-position-1 {
  top: 0;
}
.gem-position-2 {
  right: 0;
}
.gem-position-3 {
  top: 100%;
  transform: translate(0%, -100%);
}
.gem-position-4 {
  right: 0;
  top: 100%;
  transform: translate(0%, -100%);
}
.gem-desktop-wrapper {
  position: absolute;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  transition: 0.3s;
}
.gem-icon-wrapper {
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.gem-name {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.375rem;
  margin-top: 1.5rem;
  text-align: center;
  transition: 0.3s;
}
.gem-description {
  text-align: center;
  margin-top: 0.5rem;
  opacity: 0;
  transition: 0.3s;
}

.gem-desktop-wrapper:hover {
  margin-top: 0rem;
}
.gem-desktop-wrapper:hover .gem-icon-wrapper {
  transform: scale(1.15);
}
.gem-desktop-wrapper:hover .gem-name {
  margin-top: 0.5rem;
}
.gem-desktop-wrapper:hover .gem-description {
  opacity: 1;
}
