@import "../../../App.css";

.video-player-wrapper {
  border-radius: 1.25rem;
  box-shadow: 0px 32px 44px 0px rgba(129, 138, 140, 0.6);
  overflow: hidden;
}

.landing-page-hero-section-button-margin-top {
  margin-top: 2rem !important;
  float:left !important;
  /* margin-left:1.5rem !important; */
  background-color: #FFFFFF!important;
  font-weight: bold !important;
}

.gemJar-button{
  color:#307A64 !important
}
.gemEcosystem-button{
  color:#407EC4 !important
}
.carousel-status {
  display: none;
}

@media (min-width: 1024px) {
  .landing-page-hero-section-button-margin-top {
      margin-top: 2.65rem !important;
      
  }
}