.innovation-grid-background {
  background: url("../../../assets/innovationBackgroundMobileImage.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
@media (min-width: 1024px) {
  .innovation-grid-background {
    background: url("../../../assets/innovationBackgroundImage.png");
  }
}
