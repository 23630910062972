.contact-here-bg {
  background: url("../../../assets/contactHereMobileBackground.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top center !important;
}
@media (min-width: 1024px) {
  .contact-here-bg {
    background: url("../../../assets/contactHereDesktopBackground.png");
  }
}
