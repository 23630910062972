.signUp-background-wrapper {
  background: url("../../assets/signUpPageBackgroundImage.png");
  background-position: center;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
  height: 100vh !important;
}
.upper-ring{
  position: absolute !important;
  top:0 !important;
  right:0 !important;
  width:6rem !important;
}

.bottom-ring{
  position: absolute !important;
  bottom:0 !important;
  right:0 !important;
  width:10rem !important;
}

.customSignup{
    transform: scale(0.9);
    background: #ffffff !important;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2rem !important;
    max-height:90vh !important;
    }
.change-email-card {
  font-family: "Poppins", sans-serif !important;
  border-radius: 2rem !important;

}
.change-email-card h1 {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  color: #20245f;
}
.change-email-card h2 {
  font-size: 0.875rem !important;
  margin-bottom: 0rem !important;
  line-height: 150%;
}
.change-email-card h3 {
  font-size: 0.75rem !important;
  font-weight: 600;
  margin-bottom: 0rem !important;
}
.change-email-card h4 {
  font-size: 1rem !important;
  font-weight: 600;
  color: #0d6efd;
  margin-top: 1rem;
  margin-bottom: 0rem !important;
  text-decoration: underline;
  cursor: pointer;
}
.change-email-card input {
  color: black !important;
  font-weight: 500;
  background-color: #dee2e6 !important;
}
.change-email-card ::placeholder {
  opacity: 0.8;
}
.change-email-card .p-button-label {
  font-size: 1rem !important;
  letter-spacing: 1px;
}
.change-email-card_outlined-button {
  color: black !important;
}
.change-email-card_outlined-button:hover {
  color: white !important;
}
