.card-wrapper {
  border-radius: 1.25rem;
  border: 0.5px solid #d1dae7;
  padding: 3rem 1.5rem 3rem 1.5rem;
  box-shadow: 10px 40px 50px 0px rgba(229, 233, 246, 0.4);
}
.icon-wrapper {
  position: relative;
  height: 3rem;
}
.icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 3rem;
}

.heading {
  color: var(--backup-colors-dark, #1b2f3f);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 150%; /* 1.875rem */
  margin-top: 2rem;
}
.brief-description {
  display: none;
}
.description-decoration {
  margin-top: 0.5rem;
  color: #405261;
}

@media (min-width: 1024px) {
  .card-wrapper {
    position: relative;
    padding: 1.5rem;
    flex: 1;
    overflow: hidden;
  }

  .icon-wrapper {
    margin-top: 5.6rem;
    height: 5.6rem;
    transition: all 0.5s;
  }
  .icon {
    height: 5.6rem;
    transition: all 0.5s;
  }

  .heading {
    margin-top: 3rem;
    transition: all 0.5s;
  }
  .brief-description {
    display: block;
    position: absolute;
    opacity: 1;
    transition: all 0.5s;
  }
  /* increase height to increase the height of card to accomodate longer description */
  .description-wrapper {
    position: relative;
    height: 3rem;
    transition: all 0.5s;
  }
  .description {
    position: absolute;
    top: 100%;
    transform: translateY(100%);
    opacity: 0;
    transition: all 0.5s;
  }
  .card-wrapper:hover .icon-wrapper {
    margin-top: 0rem;
    height: 3rem;
  }
  .card-wrapper:hover .icon {
    height: 3rem;
    left: 0;
    transform: translateX(0);
  }

  .card-wrapper:hover .heading {
    margin-top: 1rem;
  }
  .card-wrapper:hover .brief-description {
    opacity: 0;
  }
  .card-wrapper:hover .description-wrapper {
    height: 12rem;
  }
  .card-wrapper:hover .description {
    opacity: 1;
    top: 0;
    transform: translateY(0);
  }
}
