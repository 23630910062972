.H4 {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3.125rem;
}
.H5 {
  /* H5 */
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.375rem; /* 118.75% */
}
.H6 {
  /* H6 */
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem; /* 133.333% */
}

.base-button {
  border-radius: 0.375rem !important;
  padding: 0.75rem 1.5rem !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0.05rem;
  border: none !important;
}

.component-padding {
  padding: 3rem 1.5rem;
}
@media (min-width: 1024px) {
  .hover-right-arrow::after {
    content: "";
    display: inline-block;
    opacity: 0;
    width: 0;
    height: 0.85219rem;
    background-image: url("./assets/buttonRightArrowIcon.svg");
    background-size: contain;
    background-position-y: 50%;
    background-repeat: no-repeat;
    margin-left: 0.5rem;
    transition: 0.3s ease-out;
  }
  .hover-right-arrow:hover::after {
    width: 1.95119rem;
    opacity: 1;
  }
  .component-padding {
    padding: 5rem 4rem;
  }
}
.primary-button {
  background: #407ec4 !important;
  color: #fff !important;
  border: 1px solid #407ec4 !important;
}

.secondary-button {
  background: #fff !important;
  color: #407ec4 !important;
  border: 1px solid #407ec4 !important;
}
.tertiary-button {
  background: #fff !important;
  color: #407ec4 !important;
  border: 1px solid white !important;
}

.glow-button {
  transition: 0.3s ease-out !important;
}
.glow-button:hover {
  box-shadow: 0 0rem 0.5rem #407ec4 !important;
}
.glow-button:active {
  box-shadow: none !important;
}

.p-button {
  box-shadow: none !important;
  justify-content: center;
}
.p-button-label {
  flex: unset !important;
}

.p-component {
  font-family:
    Lexend,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif !important;
}
.p-inputtext {
  font-size: 0.875rem !important ;
  font-weight: 300;
  line-height: 1.25rem !important ;
  padding: 0.75rem 1rem !important;
  border-radius: 0.5rem !important;
  border: 1px solid #d1dae7 !important;
}
.p-inputtext.p-invalid.p-component {
  border: 1px solid #c41a1a !important;
  box-shadow: none !important;
}
input {
  color: black !important;
  font-weight: 400 !important;
  caret-color: #407ec4 !important;
}
input::placeholder {
  font-weight: 300 !important;
}

.Toastify__toast-container {
  margin-top: 3rem;
}
.Toastify__toast {
  border-radius: 0.25rem !important;
  box-shadow:
    0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 8px 12px 0px rgba(9, 30, 66, 0.15) !important;
}
.Toastify__toast--success {
  border: 1px solid var(--toastify-color-success) !important;
}
.Toastify__toast--warning {
  border: 1px solid var(--toastify-color-warning) !important;
}
.Toastify__toast--error {
  border: 1px solid var(--toastify-color-error) !important;
}
.Toastify__toast--info {
  border: 1px solid var(--toastify-color-info) !important;
}
