.signUp-background-wrapper {
  background: url("../../assets/signUpPageBackgroundImage.png");
  background-position: center;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
  height: 100vh !important;
}

.upper-ring {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  width: 6rem !important;
}

.bottom-ring {
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  width: 10rem !important;
}

.customSignup {
  transform: scale(0.9);
  background: #ffffff !important;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25) !important;
  border-radius: 2rem !important;
  max-height: 90vh !important;
}

.login-wrapper {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.customSignup1 {
  max-height: 90vh !important;
  margin-top: 21% !important;
}

.custom-btn {
  background-color: #ffffff;
  color: black;
  border-radius: 3rem !important;
}

.custom-btn>img {
  width: 10% !important;
}