.accordion-wrapper {
  background: linear-gradient(3deg, #2180ca -1.94%, #2180ca 97.5%);
  padding: 2.5rem 1.5rem;
}

.footer-hr {
  background: rgba(209, 218, 231, 0.8);
}
.footer-background {
  background-image: url("../../assets/footerBackground.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: 100% auto;
}

.footer-links-heading {
  color: #333;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.6875rem */
}
.footer-links {
  color: #333;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}
.footer-links:hover {
  color: #2180ca;
}

.social-media-icons {
  transition: 0.3s ease-out;
}
.social-media-icons:hover {
  transform: scale(1.5);
}

/* -------Accordion------- */
.footer-accordion > .p-accordion-header-link {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: #fff !important;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.footer-accordion > .p-accordion-header-link > .p-accordion-header-text {
  color: #fff;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150% !important; /* 1.875rem */
}

.footer-accordion > .p-accordion .p-accordion-content {
  padding: 0rem 0rem 1rem 1.25rem !important;
}
.footer-accordion > .p-accordion-content {
  background: transparent !important;
  border: none !important;
  color: rgba(255, 255, 255, 0.95) !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
}
