.testimonials-inner-cards-wrapper {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 24px 0px #E7F2FA;   
  
  }


@media (min-width: 1024px) {
    .testimonials-inner-cards-wrapper {
      flex-direction: row !important;
      height:26.8rem;
    }
    
  }